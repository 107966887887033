<div class="flex-col">
	<nav data-role="ribbonmenu">
		<ul class="tabs-holder">
			<li>
				<a href="#section-one">{{ "MY-PROTOCOLS_MENU" | translate }}</a>
			</li>
		</ul>
		<div class="content-holder">
			<div
				class="section"
				id="section-one">
				<div class="group">
					<button
						class="ribbon-button"
						(click)="editHeader()">
						<span class="icon">
							<mat-icon>web</mat-icon>
						</span>
						<span class="caption">{{ "institute.header.title" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="editProtocolTemplate()">
						<span class="icon">
							<mat-icon>insert_drive_file</mat-icon>
						</span>
						<span class="caption">{{ "institute.protocol.template.title" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="editGlobalCodification()">
						<span class="icon">
							<mat-icon>layers</mat-icon>
						</span>
						<span class="caption">{{ "quality.codification" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="exportExcel()">
						<span class="icon">
							<mat-icon>receipt_long</mat-icon>
						</span>
						<span class="caption">{{ "MY-PROTOCOLS_EXPORT" | translate }}</span>
					</button>

					<span class="title">{{ "institute.title" | translate }}</span>
				</div>
				<div class="group">
					<button
						class="ribbon-button"
						(click)="loadArchives(false)">
						<span class="icon">
							<mat-icon>inbox</mat-icon>
						</span>
						<span class="caption">{{ "MY-PROTOCOLS_ACTIVE" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="loadArchives(true)">
						<span class="icon">
							<mat-icon>inventory</mat-icon>
						</span>
						<span class="caption">{{ "MY-PROTOCOLS_ARCHIVES" | translate }}</span>
					</button>
					<span class="title">{{ "MY-PROTOCOLS_FILTERING" | translate }}</span>
				</div>
			</div>
		</div>
	</nav>
	<div class="m-8 flex flex-row gap-x-8">
		<mat-card
			appearance="outlined"
			class="flex flex-1 flex-row">
			<mat-card-title
				><h2>
					{{ "MY-PROTOCOLS_SEARCH" | translate }}
				</h2></mat-card-title
			>
			<mat-card-content class="flex-col">
				<div class="flex w-full flex-row justify-end">
					<button
						mat-button
						(click)="clearFilters()">
						<mat-icon>clear_all</mat-icon>
					</button>
				</div>
				<mat-form-field
					appearance="outline"
					class="w-full">
					<mat-label>{{ "MY-PROTOCOLS_SEARCH_PROTOCOL" | translate }}</mat-label>
					<input
						matInput
						[(ngModel)]="searchKeyword"
						(ngModelChange)="filterProtocols()"
						spellcheck="false" />
					<mat-icon matIconPrefix>search</mat-icon>
					<mat-icon
						matIconSuffix
						(click)="clearSearchKeyword()"
						*ngIf="searchKeyword.trim() !== ''"
						class="cursor-pointer"
						>cancel</mat-icon
					>
				</mat-form-field>
				<app-tag-filter
					placeholder="{{ 'MY-PROTOCOLS_SEARCH_BY_TAG' | translate }}"
					[(tags)]="tags"
					(tagsChange)="filterProtocols()"></app-tag-filter>
			</mat-card-content>
		</mat-card>
	</div>
	<div class="flex flex-col">
		<table
			mat-table
			[dataSource]="dataSource"
			matSort>
			<ng-container matColumnDef="status">
				<th
					mat-header-cell
					*matHeaderCellDef></th>
				<td
					mat-cell
					*matCellDef="let element">
					<img
						src="{{ getStatusIcon(element) }}"
						alt="Protocol Status"
						width="16px" />
				</td>
			</ng-container>
			<ng-container matColumnDef="label">
				<th
					mat-header-cell
					*matHeaderCellDef
					mat-sort-header>
					{{ "MY-PROTOCOLS_PROTOCOL" | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let element">
					<div class="flex flex-col">
						<span [matMenuTriggerFor]="menu">{{ element.label }}</span>
						<span
							class="update-required"
							*ngIf="protocolFunctions.isExpired(element)">
							{{ "MY-PROTOCOLS_UPDATE_REQUIRED" | translate }} !
						</span>
					</div>
					<mat-menu #menu="matMenu">
						<button
							mat-menu-item
							(click)="view(element, true)"
							[disabled]="element.status !== 1">
							<mat-icon>visibility</mat-icon>
							<span>{{ "MY-PROTOCOLS_VIEW_VERSION_PUBLISHED" | translate }}</span>
						</button>
						<button
							mat-menu-item
							(click)="view(element, false)">
							<mat-icon>preview</mat-icon>
							<span>{{ "MY-PROTOCOLS_VIEW_VERSION_DRAFT" | translate }}</span>
						</button>
					</mat-menu>
				</td>
			</ng-container>
			<ng-container matColumnDef="tags">
				<th
					mat-header-cell
					*matHeaderCellDef>
					{{ "tags.title.label" | translate }}
				</th>
				<td
					mat-cell
					*matCellDef="let element">
					<mat-chip-listbox>
						<mat-chip-option *ngFor="let tag of element.tags">
							{{ tag.label }}
						</mat-chip-option>
					</mat-chip-listbox>
				</td>
			</ng-container>
			<ng-container matColumnDef="actions">
				<th
					mat-header-cell
					*matHeaderCellDef>
					{{ "core.actions" | translate }}
				</th>
				<td
					mat-cell
					class="items-between flex flex-row bg-red-300"
					*matCellDef="let element">
					<ng-container>
						<button
							mat-icon-button
							[matTooltip]="'EDIT-PROTOCOL_EDIT_CODIFICATION_PROTOCOL' | translate"
							(click)="setCodification(element)">
							<mat-icon>layers</mat-icon>
						</button>
						<button
							mat-icon-button
							[matTooltip]="'EDIT-PROTOCOL_CONSULT_HISTORY_CHANGE_PROTOCOL' | translate"
							(click)="showHistory(element)">
							<mat-icon>history</mat-icon>
						</button>
					</ng-container>
				</td>
			</ng-container>
			<tr
				mat-header-row
				*matHeaderRowDef="displayedColumns"></tr>
			<tr
				mat-row
				*matRowDef="let row; columns: displayedColumns"></tr>
		</table>
		<mat-paginator
			[pageSizeOptions]="[10, 30, 100]"
			showFirstLastButtons></mat-paginator>
	</div>
</div>
