import { Injectable } from "@angular/core";
import { Protocol } from "../../../interfaces/protocols/protocol";

@Injectable({
	providedIn: "root",
})
export class CommonProtocolsFunctionsService {
	constructor() {}

	isExpired(protocol: Protocol): boolean {
		if (protocol.updates.length > 0) {
			const lastUpdate = protocol.updates[protocol.updates.length - 1];
			let diff = new Date().getTime() - new Date(lastUpdate.updated).getTime();
			diff /= 1000 * 60 * 60 * 24 * 365;
			if (diff > 3) {
				return true;
			}
		}
		return false;
	}
}
