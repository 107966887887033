import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "../../guards/auth-guard.service";
import { UsersComponent } from "./users/users.component";
import { SecureGuard } from "../../guards/secure.guard";

const routes: Routes = [
	{
		path: "",
		canActivate: [AuthGuardService, SecureGuard],
		component: UsersComponent,
		pathMatch: "full",
	},
];

@NgModule({
	imports: [CommonModule, RouterModule.forChild(routes)],
})
export class UsersModule {}
