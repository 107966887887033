<div class="w-80 px-3 mb-8">
	<div class="max-w-sm mx-auto h-full px-4 pt-6 pb-24 rounded-xl">
		<div class="flex items-center justify-between mb-3">
			<div class="flex flex-row items-center justify-between w-full">
				<div class="text-lg font-semibold mr-2 flex-1">
					{{ title }}
				</div>
				<div class="flex flex-row items-center">
					<button
						mat-icon-button
						[matMenuTriggerFor]="menu">
						<mat-icon>sort</mat-icon>
					</button>
					<mat-menu #menu="matMenu">
						<button
							mat-menu-item
							(click)="sort(EProtocolSortType.label)">
							@if (sortType === EProtocolSortType.label) {
								<mat-icon>{{ sortAsc ? "arrow_upward" : "arrow_downward" }}</mat-icon>
							}
							{{ "protocol.sort.label" | translate }}
						</button>
						<button
							mat-menu-item
							(click)="sort(EProtocolSortType.last_update)">
							@if (sortType === EProtocolSortType.last_update) {
								<mat-icon>{{ sortAsc ? "arrow_upward" : "arrow_downward" }}</mat-icon>
							}
							{{ "protocol.sort.last-update" | translate }}
						</button>
					</mat-menu>
					<div
						class="inline-flex items-center justify-center w-6 h-7 rounded-full bg-gray-600 text-xs font-medium text-white">
						{{ _items.length }}
					</div>
				</div>
			</div>
		</div>
		<div
			class="h-1 w-full mb-4 rounded-full"
			[ngClass]="colorClass"></div>
		@for (item of _items; track item.id) {
			<app-kanban-row [item]="item"></app-kanban-row>
		}
	</div>
</div>
