<div class="flex-col">
	<nav data-role="ribbonmenu">
		<ul class="tabs-holder">
			<li>
				<a href="#section-one">{{ "MY-PROTOCOLS_MENU" | translate }}</a>
			</li>
		</ul>
		<div class="content-holder">
			<div
				class="section"
				id="section-one">
				<div class="group">
					<button
						class="ribbon-button"
						(click)="createProtocol()"
						*ngIf="manager.isManager()">
						<span class="icon">
							<mat-icon>add</mat-icon>
						</span>
						<span class="caption">{{ "MY-PROTOCOLS_NEW" | translate }}</span>
					</button>

					<button
						class="ribbon-button"
						(click)="exportExcel()">
						<span class="icon">
							<mat-icon>receipt_long</mat-icon>
						</span>
						<span class="caption">{{ "MY-PROTOCOLS_EXPORT" | translate }}</span>
					</button>

					<button
						class="ribbon-button"
						(click)="notebooks()">
						<span class="icon">
							<mat-icon>menu_book</mat-icon>
						</span>
						<span class="caption">{{ "MY-PROTOCOLS_NOTEBOOK" | translate }}</span>
					</button>

					<button
						class="ribbon-button"
						[matMenuTriggerFor]="menuView">
						<span class="icon">
							<mat-icon>view_carousel</mat-icon>
						</span>
						<span class="caption">{{ "protocols.kanban.view" | translate }}</span>
					</button>
					<mat-menu #menuView="matMenu">
						<button
							mat-menu-item
							(click)="setCurrentView(EAuthorProtocolsView.LIST)">
							<mat-icon>list</mat-icon>
							Vue liste
						</button>
						<button
							mat-menu-item
							(click)="setCurrentView(EAuthorProtocolsView.COLUMN)">
							<mat-icon>view_kanban</mat-icon>
							Vue colonne
						</button>
					</mat-menu>

					<button
						class="ribbon-button"
						(click)="openRules()">
						<span class="icon">
							<mat-icon>webhook</mat-icon>
						</span>
						<span class="caption">{{ "rules.engine.label" | translate }}</span>
					</button>
					<span class="title">{{ "MY-PROTOCOLS_PROTOCOLS" | translate }}</span>
				</div>

				<div
					class="group"
					*ngIf="manager.isManager()">
					<button
						class="ribbon-button"
						(click)="shareMyProtocols()">
						<span class="icon">
							<mat-icon>share</mat-icon>
						</span>
						<span class="caption">{{ "MY-PROTOCOLS_SHARE_SPAN_CAPTION" | translate }}</span>
					</button>

					<button
						class="ribbon-button"
						[disabled]="true">
						<span class="icon">
							<mat-icon>fact_check</mat-icon>
						</span>
						<span class="caption">{{ "MY-PROTOCOLS_CATALOG" | translate }}</span>
					</button>
					<span class="title">{{ "MY-PROTOCOLS_SHARE_SPAN_TITLE" | translate }}</span>
				</div>
				<div class="group">
					<button
						class="ribbon-button"
						(click)="loadArchives(false)">
						<span class="icon">
							<mat-icon>inbox</mat-icon>
						</span>
						<span class="caption">{{ "MY-PROTOCOLS_ACTIVE" | translate }}</span>
					</button>
					<button
						class="ribbon-button"
						(click)="loadArchives(true)">
						<span class="icon">
							<mat-icon>inventory</mat-icon>
						</span>
						<span class="caption">{{ "MY-PROTOCOLS_ARCHIVES" | translate }}</span>
					</button>
					<button
						*ngIf="manager.isManager()"
						class="ribbon-button"
						(click)="openNavigationSpecialties()">
						<span class="icon">
							<mat-icon>home_work</mat-icon>
						</span>
						<span class="caption">{{ "my-protocols.ribbon.filter.specialties.label" | translate }}</span>
					</button>
					<span class="title">{{ "MY-PROTOCOLS_FILTERING" | translate }}</span>
				</div>

				<div class="group">
					<button
						class="ribbon-button"
						(click)="guidelines()">
						<span class="icon">
							<mat-icon>stream</mat-icon>
						</span>
						<span class="caption">{{ "my-protocols.ribbon.guidelines.title" | translate }}</span>
					</button>
					<span class="title">{{ "my-protocols.ribbon.knowledge.title" | translate }}</span>
				</div>
				<div class="group"></div>
			</div>
		</div>
	</nav>
	<div class="flex flex-row">
		<mat-card
			appearance="outlined"
			class="w-2/3 flex-initial">
			<mat-card-title
				><h2>
					{{ "MY-PROTOCOLS_SEARCH" | translate }}
				</h2></mat-card-title
			>
			<mat-card-content class="flex-col">
				<div class="flex w-full flex-row justify-end">
					<button
						mat-button
						(click)="clearFilters()">
						<mat-icon>clear_all</mat-icon>
					</button>
				</div>
				<mat-form-field
					appearance="outline"
					class="w-full">
					<mat-label>{{ "MY-PROTOCOLS_SEARCH_PROTOCOL" | translate }}</mat-label>
					<input
						matInput
						[(ngModel)]="searchKeyword"
						(ngModelChange)="filterProtocols()"
						spellcheck="false" />
					<mat-icon matIconPrefix>search</mat-icon>
					<mat-icon
						matIconSuffix
						(click)="clearSearchKeyword()"
						*ngIf="searchKeyword.trim() !== ''"
						class="cursor-pointer"
						>cancel
					</mat-icon>
				</mat-form-field>
				<app-tag-filter
					placeholder="{{ 'MY-PROTOCOLS_SEARCH_BY_TAG' | translate }}"
					[(tags)]="tags"
					(tagsChange)="filterProtocols()"></app-tag-filter>
				<div class="flex flex-col">
					<p class="flex flex-row justify-between">
						<mat-checkbox
							[(ngModel)]="filterValues.writer"
							(ngModelChange)="filterProtocols()"
							>{{ "MY-PROTOCOLS_EDITOR" | translate }}
						</mat-checkbox>
						<mat-checkbox
							[(ngModel)]="filterValues.approver"
							(ngModelChange)="filterProtocols()"
							>{{ "MY-PROTOCOLS_APPROVER" | translate }}
						</mat-checkbox>
						<mat-checkbox
							[(ngModel)]="filterValues.manager"
							(ngModelChange)="filterProtocols()"
							>{{ "MY-PROTOCOLS_MANAGER" | translate }}
						</mat-checkbox>
					</p>
					<p>
						<mat-radio-group
							class="flex flex-row justify-between"
							[(ngModel)]="filterValues.status"
							(ngModelChange)="filterProtocols()">
							<mat-radio-button [value]="-1"
								>{{ "MY-PROTOCOLS_ALL_PROTOCOLS_1" | translate }}
							</mat-radio-button>
							<mat-radio-button [value]="1"
								>{{ "MY-PROTOCOLS_PROTOCOLS_PUBLISHED" | translate }}
							</mat-radio-button>
							<mat-radio-button [value]="0"
								>{{ "MY-PROTOCOLS_PROTOCOLS_NON_PUBLISHED" | translate }}
							</mat-radio-button>
						</mat-radio-group>
					</p>
					<p>
						<mat-radio-group
							class="flex flex-row justify-between"
							[(ngModel)]="filterValues.update"
							(ngModelChange)="filterProtocols()">
							<mat-radio-button [value]="-1"
								>{{ "MY-PROTOCOLS_ALL_PROTOCOLS_2" | translate }}
							</mat-radio-button>
							<mat-radio-button [value]="1"
								>{{ "MY-PROTOCOLS_PROTOCOLS_UPTODATE" | translate }}
							</mat-radio-button>
							<mat-radio-button [value]="0"
								>{{ "MY-PROTOCOLS_PROTOCOLS_NON_UPTODATE" | translate }}
							</mat-radio-button>
						</mat-radio-group>
					</p>
				</div>
			</mat-card-content>
		</mat-card>

		<mat-card
			appearance="outlined"
			class="w-1/3 flex-initial">
			<mat-card-title>
				<div class="flex flex-row items-center justify-center">
					<mat-icon>multiline_chart</mat-icon>
				</div>
			</mat-card-title>
			<mat-card-content>
				<p
					*ngFor="let item of statistics"
					class="flex flex-row justify-between">
					<label>{{ item.key }} :</label>
					<label>{{ item.value }}</label>
				</p>
			</mat-card-content>
		</mat-card>
	</div>
	@switch (currentView) {
		@case (EAuthorProtocolsView.PENDING) {
			<app-loading-modal></app-loading-modal>
		}
		@case (EAuthorProtocolsView.LIST) {
			<div class="flex flex-col">
				<table
					mat-table
					[dataSource]="dataSource"
					matSort>
					<ng-container matColumnDef="share">
						<th
							mat-header-cell
							*matHeaderCellDef></th>
						<td
							mat-cell
							*matCellDef="let element">
							<mat-icon
								*ngIf="!isSameInstitute(element)"
								[matTooltip]="element.institute.label"
								>share
							</mat-icon>
						</td>
					</ng-container>
					<ng-container matColumnDef="status">
						<th
							mat-header-cell
							*matHeaderCellDef></th>
						<td
							mat-cell
							*matCellDef="let element">
							<img
								src="{{ getStatusIcon(element) }}"
								alt="Protocol Status"
								width="16px" />
						</td>
					</ng-container>
					<ng-container matColumnDef="label">
						<th
							mat-header-cell
							*matHeaderCellDef
							mat-sort-header>
							{{ "MY-PROTOCOLS_PROTOCOL" | translate }}
						</th>
						<td
							mat-cell
							*matCellDef="let element">
							<div class="flex flex-col">
								<div>
									<span
										[matMenuTriggerFor]="menu"
										class="text-blue-800"
										>{{ element.label
										}}<span
											*ngIf="getErrorsCount(element) > 0"
											matBadge="{{ getErrorsCount(element) }}"
											matBadgeOverlap="false"
											matBadgeColor="warn"></span
									></span>
									<span
										class="update-required"
										*ngIf="protocolFunctions.isExpired(element)">
										- {{ "MY-PROTOCOLS_UPDATE_REQUIRED" | translate }} !</span
									>
								</div>
								<div class="text-xs italic">{{ element.tags | tagsString }}</div>
							</div>
							<mat-menu #menu="matMenu">
								<button
									mat-menu-item
									[routerLink]="'/protocols/' + element.id + '/edit'"
									[state]="{ data: element }"
									[disabled]="element.status === ProtocolStatus.archived">
									<mat-icon>edit</mat-icon>
									<span>{{ "core.edit" | translate }}</span>
								</button>
								<button
									mat-menu-item
									(click)="unarchive(element)"
									*ngIf="element.status === 2 && manager.isManager()">
									<mat-icon>unarchive</mat-icon>
									<span>{{ "protocol.edit.toolbar.management.dearchive.label" | translate }}</span>
								</button>
								<button
									mat-menu-item
									(click)="view(element, true)"
									[disabled]="element.status !== 1">
									<mat-icon>visibility</mat-icon>
									<span>{{ "MY-PROTOCOLS_VIEW_VERSION_PUBLISHED" | translate }}</span>
								</button>
								<button
									mat-menu-item
									(click)="view(element, false)">
									<mat-icon>preview</mat-icon>
									<span>{{ "MY-PROTOCOLS_VIEW_VERSION_DRAFT" | translate }}</span>
								</button>
							</mat-menu>
						</td>
					</ng-container>
					<ng-container matColumnDef="institute">
						<th
							mat-header-cell
							*matHeaderCellDef
							mat-sort-header>
							{{ "MY-PROTOCOLS_ESTABLISHMENT" | translate }}
						</th>
						<td
							mat-cell
							*matCellDef="let element">
							<span *ngIf="!isSameInstitute(element)">{{ element.institute.label }}</span>
						</td>
					</ng-container>
					<tr
						mat-header-row
						*matHeaderRowDef="displayedColumns"></tr>
					<tr
						mat-row
						*matRowDef="let row; columns: displayedColumns"></tr>
				</table>
				<mat-paginator
					[pageSizeOptions]="[10, 30, 100]"
					showFirstLastButtons></mat-paginator>
			</div>
		}
		@case (EAuthorProtocolsView.COLUMN) {
			<app-protocols-kanban
				[offlineProtocols]="getOfflineProtocols()"
				[onlineProtocols]="getOnlineProtocols()"
				[archivedProtocols]="getArchivedProtocols()"
				[waitingApprovalProtocols]="getWaitingApprovalProtocols()"></app-protocols-kanban>
		}
	}
</div>
