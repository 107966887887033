import { Component, Input } from "@angular/core";
import { NgClass } from "@angular/common";
import { KanbanRowComponent } from "../kanban-row/kanban-row.component";
import { Protocol } from "../../../../interfaces/protocols/protocol";
import { MatIconButton } from "@angular/material/button";
import { MatIcon } from "@angular/material/icon";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { EProtocolSortType } from "../../../protocols/enums/eprotocol-sort-type";
import { NgxTolgeeModule } from "@tolgee/ngx";

@Component({
	selector: "app-kanban-list",
	imports: [
		NgClass,
		KanbanRowComponent,
		MatIconButton,
		MatIcon,
		MatMenu,
		MatMenuItem,
		MatMenuTrigger,
		NgxTolgeeModule,
	],
	templateUrl: "./kanban-list.component.html",
	styleUrl: "./kanban-list.component.scss",
})
export class KanbanListComponent {
	@Input() title: string = "";
	@Input() colorClass: string = "";
	_items: Protocol[] = [];
	sortType: string = EProtocolSortType.label;
	sortAsc: boolean = true;

	@Input() set items(value: Protocol[]) {
		switch (this.sortType) {
			case EProtocolSortType.label:
				this._items = value.sort((a, b) =>
					this.sortAsc ? a.label.localeCompare(b.label) : b.label.localeCompare(a.label)
				);
				break;
			case EProtocolSortType.last_update:
				this._items = value.sort((a, b) =>
					this.sortAsc
						? a.last_change.localeCompare(b.last_change)
						: b.last_change.localeCompare(a.last_change)
				);
				break;
		}
	}

	sort(value: string): void {
		if (this.sortType !== "") {
			this.sortAsc = !this.sortAsc;
		}
		this.sortType = value;
	}

	protected readonly EProtocolSortType = EProtocolSortType;
}
